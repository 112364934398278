import { useEffect, useState } from 'react';

interface VideoMeta {
  width: number;
  height: number;
}

export function useVideoMeta(videoFile?: File): VideoMeta | undefined {
  const [meta, setMeta] = useState<VideoMeta | undefined>();

  useEffect(() => {
    if (!videoFile) {
      return;
    }
    console.log(' videoFile ', videoFile);
    const video = document.createElement('video');
    //video.setAttribute('style', 'display: none');
    video.setAttribute('id', 'buffVideo');

    const fileReader = new FileReader();
    fileReader.onload = function () {
      if (!fileReader.result) {
        return;
      }
      const blob = new Blob([fileReader.result], { type: videoFile.type });
      const url = URL.createObjectURL(blob);

      video.onloadedmetadata = () => {
        console.log('loaded onloadedmetadata');
        const diffWidth = video.videoWidth / video.videoHeight;
        const diffHeight = video.videoHeight / video.videoWidth;

        setMeta({
          width: 1,
          height: diffHeight,
        });

        video.pause();
      };

      video.preload = 'metadata';
      video.src = url;
      // Load video in Safari / IE11
      video.muted = false;
      video.playsInline = true;

      console.log('onload video ');
    };
    fileReader.readAsArrayBuffer(videoFile);

    return () => {
      video.remove();
    };
  }, [videoFile]);

  if (!videoFile) {
    return;
  }

  return meta;
}
