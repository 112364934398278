import {
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  memo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import { Application } from '@src/adapters/apps';
import { DataItem as DataItemDto } from '@src/adapters/data-item';
import { CREATE_AR_FORM_MODE, CREATE_AR_STEP, DataItem } from '@src/core/types';
import { useNav } from '@src/hooks/use-nav';

export const CreateArContext = createContext<{
  currentStep: CREATE_AR_STEP;
  currentStepIndex: number;
  onNext: () => void;
  onPrev: () => void;
  dataItem?: DataItem;
  app?: Application;
  mode: CREATE_AR_FORM_MODE;

  disabledNext: boolean;
  onDataItemChange: (item: SetStateAction<DataItem | undefined>) => void;
  setDisabledNext: (value: SetStateAction<boolean>) => void;
  onAppChange: (value: SetStateAction<Application | undefined>) => void;
}>({
  currentStep: CREATE_AR_STEP.APP,
  currentStepIndex: 0,
  disabledNext: false,
  mode: CREATE_AR_FORM_MODE.CREATE,

  onNext: () => {},
  onPrev: () => {},
  onDataItemChange: () => {},
  onAppChange: () => {},
  setDisabledNext: () => {},
});

export const CREATE_AR_ORDER = [
  CREATE_AR_STEP.APP,
  CREATE_AR_STEP.SCENARIO,
  CREATE_AR_STEP.SETTINGS,
];

interface CreateArProviderProps extends PropsWithChildren {
  mode?: CREATE_AR_FORM_MODE;
}

export const CreateArProvider: FC<CreateArProviderProps> = memo(
  ({ children, mode = CREATE_AR_FORM_MODE.CREATE }) => {
    const [currentStep, setCurrentStep] = useState<CREATE_AR_STEP>(
      CREATE_AR_STEP.APP
    );

    const { id } = useParams();
    const [disabledNext, setDisabledNext] = useState<boolean>(false);
    const [dataItem, setDataItem] = useState<DataItem | undefined>();
    const [app, setApp] = useState<Application | undefined>();
    const { currentIndex, handleNext, handlePrev } = useNav<CREATE_AR_STEP>(
      CREATE_AR_ORDER,
      currentStep,
      setCurrentStep
    );
    const parsedId = Number(id) || undefined;

    const { loading, data } = useQuery<{ dataItem: DataItemDto }>(
      queries.dataItem.getDataItem,
      {
        skip: !parsedId,
        variables: {
          id: parsedId,
        },
        onCompleted: (data) => {
          if (data.dataItem) {
            const dataItem = data.dataItem;
            setDataItem({
              name: dataItem.name,
              description: dataItem.description,
              type: dataItem.itemType,
              scenery: dataItem.triggerType,
              applicationId: dataItem.contributor?.application?.id,
              dataPackageId: dataItem.dataPackage?.id,
              createdAt: dataItem.createdAt,
              id: dataItem.id,
              thumbnailFile: dataItem.thumbnailPath,
              filePath: dataItem.filePath,
              scale: dataItem.scale,
              offsetX: dataItem.offsetX,
              offsetY: dataItem.offsetY,
              offsetZ: dataItem.offsetZ,
              triggerFilePath: dataItem.triggerFilePath,
            });
            setCurrentStep(CREATE_AR_STEP.SETTINGS);
            setApp({
              id: dataItem.contributor?.application?.id || 0,
              name: dataItem.contributor?.application?.name || '',
              isPublic: dataItem.contributor?.application?.isPublic || true,
              apiURL: dataItem.contributor?.application?.apiURL || '',
              platforms: dataItem.contributor?.application?.platforms || [],
              scenaries: dataItem.contributor?.application?.scenaries || [],
            });
          }
        },
      }
    );

    return (
      <CreateArContext.Provider
        value={{
          currentStep,
          currentStepIndex: currentIndex,
          onNext: handleNext,
          onPrev: handlePrev,
          disabledNext,
          app,
          mode,

          dataItem,
          onDataItemChange: setDataItem,
          setDisabledNext,
          onAppChange: setApp,
        }}
      >
        {children}
      </CreateArContext.Provider>
    );
  }
);
